import React, {useRef, useState, useEffect, PureComponent} from 'react';
import axios, {isCancel, AxiosError} from 'axios';
import { Brush, AreaChart, Area, LineChart, Line, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { kindOfContracts, sumOfColumn, sumValues, compareStrings, isLoggedIn, getPrivacyStandard, splitRows, splitColumn, removeDuplicates, parseDate, getDaysArray } from '../helpers.js';
import { Calendar } from 'primereact/calendar/calendar.esm.js';

let domainOrigin = "";
let origin = window.origin;
if(origin.includes("reporting.milgenx.com")){
    domainOrigin = "https://reportingapi.milgenx.com";
}else{
    domainOrigin = "http://localhost:8080";   
}

const Staffanalysis = (props) => {
    let typeOfContracts = kindOfContracts();
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [countByDate, setCountByDate] = useState("");
    const [rowClick, setRowClick] = useState(true);

    let userRecords = props.items;
    let allStaffs = [];
    let recordByStaffs = [];

    const [dateRange, setdateRange] = useState(null);
    let fromDate = '';
    let endDate = '';
    if(dateRange){
        let unFilteredRecords = userRecords;
        if(dateRange[0]){
            fromDate = parseDate(dateRange[0]);
        }
        if(dateRange[1]){
            endDate = parseDate(dateRange[1]);
        }

        var selectedDays = [];
        let filteredRecords = []
        if(fromDate && endDate){
            selectedDays = getDaysArray(new Date(fromDate),new Date(endDate));
            selectedDays.map((v)=>v.toISOString().slice(0,10)).join("");
            selectedDays.forEach(function(selectedDay){
                let recordDay = parseDate(selectedDay);
                filteredRecords.push(splitRows(unFilteredRecords, 'date', recordDay));
            })
        }else{
            selectedDays = parseDate(dateRange[0]);
            filteredRecords.push(splitRows(unFilteredRecords, 'date', selectedDays));
        }
        
        

        if(filteredRecords.length > 0){
            filteredRecords = filteredRecords.flat();
            allStaffs = splitColumn(filteredRecords, "freelancer_name");
            userRecords = filteredRecords;
        }
    }else{
        // allStaffs = splitColumn(userRecords, "freelancer_name");
        // userRecords = userRecords;

        allStaffs = splitColumn(userRecords, "freelancer_name");
        let filteredRecords = [];

        var currentDate = new Date();
        let fromDate = parseDate(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1));
        let endDate = parseDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0))

        if(fromDate && endDate){
            selectedDays = getDaysArray(new Date(fromDate),new Date(endDate));
            selectedDays.map((v)=>v.toISOString().slice(0,10)).join("");
            selectedDays.forEach(function(selectedDay){
                let recordDay = parseDate(selectedDay);
                filteredRecords.push(splitRows(userRecords, 'date', recordDay));
            })
        }

        if(filteredRecords.length > 0){
            filteredRecords = filteredRecords.flat();
            allStaffs = splitColumn(filteredRecords, "freelancer_name");
            userRecords = filteredRecords;
        }
    }

    allStaffs = removeDuplicates(allStaffs);

    allStaffs.forEach(function(staff, index){
        let staffRecords = splitRows(userRecords, "freelancer_name", staff);
        let staffRecordsByContract, records = [];
        let totalBilledTimeSpents = 0;
        let totalNonBilledTimeSpents = 0;
        let totalFixedTimeSpents = 0;
        let totalTimeSpents = 0;
        let totalInvoiceBilledTimeSpents = 0;

        // console.log("staffRecords", staffRecords)
        typeOfContracts.forEach(function(type_of_contract){
            let typeOfContract = type_of_contract.code
            // For total time spents
            staffRecordsByContract = splitRows(staffRecords, "type_of_contract", typeOfContract);
          
            let timeSpents = splitColumn(staffRecordsByContract, "worked_hours");

            // Total time spents calculation
            timeSpents.forEach(function(timeSpent){
                let TotalMinute = Number(timeSpent.split(":")[0] * 60) + Number(timeSpent.split(":")[1]);
                let workedHours = Number(TotalMinute / 60);
                totalTimeSpents = (workedHours) ? totalTimeSpents + workedHours : totalTimeSpents + 0

                console.log("workedHours", timeSpent, workedHours)
                
                // Total Billed Hours Calculation
                if(typeOfContract == 'billable'){
                    let TotalBilledMinute = Number(timeSpent.split(":")[0] * 60) + Number(timeSpent.split(":")[1]);
                    let BilledWorkedHours = Number(TotalBilledMinute / 60);
                    totalBilledTimeSpents = (BilledWorkedHours) ? Number(totalBilledTimeSpents + BilledWorkedHours) : Number(totalBilledTimeSpents + 0);
                }

                // Total Non Billed Hours Calculation
                if(typeOfContract == 'non-billable'){
                    let TotalNonBilledMinute = Number(timeSpent.split(":")[0] * 60) + Number(timeSpent.split(":")[1]);
                    let NonBilledWorkedHours = Number(TotalNonBilledMinute / 60);
                    totalNonBilledTimeSpents = (NonBilledWorkedHours) ? totalNonBilledTimeSpents + NonBilledWorkedHours : totalNonBilledTimeSpents + 0;
                }

                if(typeOfContract == 'billable-by-invoice'){
                    let TotalInvoicedMinute = Number(timeSpent.split(":")[0] * 60) + Number(timeSpent.split(":")[1]);
                    let InvoicedWorkedHours = Number(TotalInvoicedMinute / 60);
                    totalInvoiceBilledTimeSpents = (InvoicedWorkedHours) ? totalInvoiceBilledTimeSpents + InvoicedWorkedHours : totalInvoiceBilledTimeSpents + 0;
                }

                // Total Fixed Hours Calculation
                if(typeOfContract == 'fixed-cost'){
                    let TotalFixedBilledMinute = Number(timeSpent.split(":")[0] * 60) + Number(timeSpent.split(":")[1]);
                    let FixedWorkedHours = Number(TotalFixedBilledMinute / 60);
                    totalFixedTimeSpents = totalFixedTimeSpents + FixedWorkedHours;
                }
            })
            records.push({contractType:typeOfContract, contractRecords: staffRecordsByContract});
        })
        recordByStaffs.push({staffName: staff, staffRecords: staffRecords, staffRecordsByContract: records, "Billed Hours": totalBilledTimeSpents.toFixed(2), "Billable By Invoice": totalInvoiceBilledTimeSpents.toFixed(2), "Non Billed Hours": totalNonBilledTimeSpents.toFixed(2), "Billed Fixed Hours": totalFixedTimeSpents.toFixed(2), "Total Worked Hours": totalTimeSpents.toFixed(2) });
    })

    // console.log({TableName, productHandle});   
    let [listCount, setListCount] = useState(1);
    const [ignored, forceUpdate] = useState(listCount);

    const [date, setDate] = useState(null);


    let worked_hours = sumOfColumn(splitColumn(recordByStaffs, "Total Worked Hours")).toFixed(2);
    let billed_hours = sumOfColumn(splitColumn(recordByStaffs, "Billed Hours")).toFixed(2);
    let invoice_billed_hours = sumOfColumn(splitColumn(recordByStaffs, "Billable By Invoice")).toFixed(2);
    let nonbilled_hours = sumOfColumn(splitColumn(recordByStaffs, "Non Billed Hours")).toFixed(2);
    let fixedcost_hours = sumOfColumn(splitColumn(recordByStaffs, "Billed Fixed Hours")).toFixed(2);
    console.log("recordByStaffs", recordByStaffs, worked_hours)

    // console.log(splitColumn(recordByStaffs, "Total Worked Hours"))

    return (
        <div className='container-fluid'>
        <div className='row py-2 flex-wrap mb-2 align-items-center'>
            <div className='col-6 col-sm-6 col-md-3'>
                <Calendar value={dateRange} onChange={(e) => setdateRange(e.value)} selectionMode="range" readOnlyInput showIcon showButtonBar className='text-small' />
            </div>
        </div>
        <div className='row py-2'>
            <div className='col-12 col-sm-6 col-md-3 mb-2'>
                <div className='status-block w-full bg-blue'>
                    <small>Worked Hrs: </small>
                    <strong>{worked_hours}</strong>
                </div>
            </div>
            <div className='col-6 col-sm-6 col-md-2 mb-2'>
                <div className='status-block w-full label-billable'>
                    <small>Billed: </small>
                    <strong>{billed_hours}</strong>
                </div>
            </div>
            <div className='col-6 col-sm-4 col-md-2 mb-2'>
                <div className='status-block w-full label-billable-by-invoice'>
                    <small>Invoiced: </small>
                    <strong>{invoice_billed_hours}</strong>
                </div>
            </div>
            <div className='col-6 col-sm-4 col-md-2 mb-2'>
                <div className='status-block w-full label-fixed-cost'>
                    <small>Fixed Billed: </small>
                    <strong>{fixedcost_hours}</strong>
                </div>
            </div>
            <div className='col-6 col-sm-4 col-md-3 mb-2'>
                <div className='status-block w-full label-non-billable'>
                    <small>Non Billed: </small>
                    <strong>{nonbilled_hours}</strong>
                </div>
            </div>
        </div>
        <ResponsiveContainer width="100%" height="100%" className="fit-inbody">
        <BarChart width={500} height={300} data={recordByStaffs} margin={{ top: 20, right: 10, left: 0, bottom: 5, }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="staffName" />
          <YAxis />
          <Tooltip />
          <Legend />
            <Bar dataKey="Billed Hours" stackId="a" fill="#13544e" />
            <Bar dataKey="Billable By Invoice" stackId="a" fill="#5d8fe1" />
            <Bar dataKey="Billed Fixed Hours" stackId="a" fill="orange" />
            <Bar dataKey="Non Billed Hours" stackId="a" fill="maroon" />
        </BarChart>
      </ResponsiveContainer>
        </div>
    )
} 

export default Staffanalysis;