import React, { useState, useRef, useContext } from 'react';
import { InputText } from "primereact/inputtext/inputtext.esm.js";
import { Button } from 'primereact/button/button.esm.js';
import { Dropdown } from 'primereact/dropdown/dropdown.esm.js';
import { InputTextarea } from "primereact/inputtextarea/inputtextarea.esm.js";
import { Calendar } from 'primereact/calendar/calendar.esm.js';
import axios from 'axios';
import Cookies from 'js-cookie';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';
import { context } from './Clients.js';
import { billingMethods, clientSource, isLoggedIn, splitColumn, removeDuplicates, splitRows } from '../helpers.js';

let domainOrigin = window.origin.includes("reporting.milgenx.com")
  ? "https://reportingapi.milgenx.com"
  : "http://localhost:8080";

const Addclient = (props) => {
  const [userData] = useState(isLoggedIn(Cookies));
  const [projectList, setProjectList] = useState([]);
  const [entryAdded, setEntryAdded] = useContext(context);
  const [newClient, setnewClient] = useState({
    client_name: "",
    client_source: "",
    billing_method: "",
  });

  const billingMethodList = billingMethods();
  const clientSourceList = clientSource();
  const navigate = useNavigate();

  const handleInput = (e) => {
    const { name, value } = e.target;

    if (name === "client_name") {
      const clientName = typeof value === 'object' ? value.clientName : value;
      const clientProjects = splitRows(
        splitRows(props.projects, "project_status", "In Progress"),
        "client_name",
        clientName
      );
    }

    setnewClient((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newRecord = {
      ...newClient,
      id: new Date().getTime().toString(),
      client_email: newClient.client_email,
      client_phone: newClient.client_phone,
      client_country: newClient.client_country,
      client_address: newClient.client_address,
      company_name: newClient.company_name,
      notes: newClient.notes,
      client_source: newClient.client_source?.code || "",
      billing_method: newClient.billing_method?.code || "",
      client_name:
        typeof newClient.client_name === 'object'
          ? newClient.client_name.clientName
          : newClient.client_name,
    };

    try {
      const response = await axios.post(`${domainOrigin}/client/add`, { newRecord });
      if (response.status === 200) {
        swal({
          title: "Added Successfully",
          icon: "success",
        }).then(() => {
          setEntryAdded((prevState) => prevState + 1);
          setnewClient({
            date: new Date(),
            notes: "",
            client_name: "",
            billing_method: "",
            client_source: "",
            client_email: "",
            client_phone: "",
            client_country: "",
            company_name: "",
          });
        });
      }
    } catch (error) {
      swal({
        title: "Error",
        text: error.message,
        icon: "warning",
      });
    }
  };

  const clientList = props.items
    ? removeDuplicates(splitColumn(props.items, "client_name")).map((client) => ({
        clientName: client,
      }))
    : [];

  return (
    <div className="col-12 flex flex-column align-items-center text-left">
      <form id="addrecordForm" className="row form-theme" onSubmit={handleSubmit}>
        <div className="col-12">
          <small className="d-block text-left full-width"><b>Register Client</b></small>
        </div>
        <div className="col-6 col-sm-4 col-md-4 col-lg-12 field-row">
          <Dropdown
            className="p-inputtext-sm"
            placeholder="Client Name"
            options={clientList}
            optionLabel="clientName"
            name="client_name"
            value={newClient.client_name}
            onChange={handleInput}
            editable
          />
        </div>
        <div className="col-6 col-sm-6 col-md-6 col-lg-12 field-row">
          <InputText
            className="p-inputtext-sm"
            placeholder="Company Name"
            name="company_name"
            value={newClient.company_name}
            onChange={handleInput}
            required
          />
        </div>
        <div className="col-6 col-sm-6 col-md-6 col-lg-12 field-row">
          <InputText
            className="p-inputtext-sm"
            placeholder="Client Email"
            autoComplete="off"
            name="client_email"
            value={newClient.client_email}
            onChange={handleInput}
          />
        </div>
        <div className="col-6 col-sm-4 col-md-6 col-lg-6 field-row">
          <InputText
            className="p-inputtext-sm"
            placeholder="Client Phone"
            autoComplete="off"
            name="client_phone"
            value={newClient.client_phone}
            onChange={handleInput}
          />
        </div>
        <div className="col-6 col-sm-6 col-md-6 col-lg-6 field-row">
          <InputText
            className="p-inputtext-sm"
            placeholder="Client Country"
            name="client_country"
            value={newClient.client_country}
            onChange={handleInput}
            required
          />
        </div>
        <div className="col-6 col-sm-6 col-md-6 col-lg-6 field-row">
          <Dropdown
            className="p-inputtext-sm"
            placeholder="Client Source"
            options={clientSourceList}
            optionLabel="name"
            name="client_source"
            value={newClient.client_source}
            onChange={handleInput}
            required
          />
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 field-row">
          <Dropdown
            className="p-inputtext-sm"
            placeholder="Billing Method"
            options={billingMethodList}
            optionLabel="name"
            name="billing_method"
            value={newClient.billing_method}
            onChange={handleInput}
            required
          />
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 field-row">
          <InputTextarea
            className="p-inputtext-sm"
            placeholder="Client Address"
            name="client_address"
            value={newClient.client_address}
            onChange={handleInput}
          />
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 field-row">
          <InputTextarea
            className="p-inputtext-sm"
            placeholder="Notes"
            name="notes"
            value={newClient.notes}
            onChange={handleInput}
          />
        </div>
        <div className="col-12 col-md-12 col-lg-12 field-row">
          <Button type="submit" label="Add Client" in-progress="Adding.." size="Small" className="small" />
        </div>
      </form>
    </div>
  );
};

export default Addclient;