import React, { useState, useRef, useContext } from 'react';
import { InputText } from "primereact/inputtext/inputtext.esm.js";
import { Button } from 'primereact/button/button.esm.js';
import { Dropdown } from 'primereact/dropdown/dropdown.esm.js';
import { InputTextarea } from "primereact/inputtextarea/inputtextarea.esm.js";
import { Calendar } from 'primereact/calendar/calendar.esm.js';
import axios from 'axios';
import Cookies from 'js-cookie';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';
import { context } from './Recordsbysource.js';
import { kindOfContracts, isLoggedIn, splitColumn, removeDuplicates, splitRows } from '../helpers.js';

let domainOrigin = window.origin.includes("reporting.milgenx.com")
  ? "https://reportingapi.milgenx.com"
  : "http://localhost:8080";

const Addrecord = (props) => {
  const [userData] = useState(isLoggedIn(Cookies));
  const [projectList, setProjectList] = useState([]);
  const [entryAdded, setEntryAdded] = useContext(context);
  const [newRecordData, setNewRecordData] = useState({
    date: new Date(),
    freelancer_name: userData.userName,
    task_memo: "",
    client_name: "",
    type_of_contract: "",
    worked_hours: "",
  });

  const typeOfContracts = kindOfContracts();
  const navigate = useNavigate();

  const handleInput = (e) => {
    const { name, value } = e.target;

    if (name === "client_name") {
      const clientName = typeof value === 'object' ? value.clientName : value;
      const clientProjects = splitRows(
        splitRows(props.projects, "project_status", "In Progress"),
        "client_name",
        clientName
      );

      const updatedProjectList = clientProjects.map(clientProject => ({
        name: clientProject.project_name,
        code: clientProject.project_id,
      }));
      setProjectList(updatedProjectList);
    }

    setNewRecordData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Update Button
    let formBtn = e.target.querySelector("button[type=submit]");
    formBtn.setAttribute("disabled", "disabled");
    formBtn.querySelector(".p-button-label").innerText = formBtn.getAttribute("in-progress");

    let date = newRecordData.date;
    let folteretedDate = date.toLocaleString('en-IN', {timeZone: 'IST'}).split(',')[0].split("/");
    let parsedDate = folteretedDate[1]+"/"+folteretedDate[0]+"/"+folteretedDate[2];

    console.log({parsedDate})

    const newRecord = {
      ...newRecordData,
      id: new Date().getTime().toString(),
      date: parsedDate,
      type_of_contract: newRecordData.type_of_contract?.code || "",
      project_id: newRecordData.project_id?.code || "",
      client_name:
        typeof newRecordData.client_name === 'object'
          ? newRecordData.client_name.clientName
          : newRecordData.client_name,
    };

    try {
      const response = await axios.post(`${domainOrigin}/daily-reporting/submit`, { newRecord });
      if (response.status === 200) {
        // swal({
        //   title: "Added Successfully",
        //   icon: "success",
        // }).then(() => {
          
        // });

        setEntryAdded((prevState) => prevState + 1);
        setNewRecordData({
          date: new Date(),
          freelancer_name: userData.userName,
          task_memo: "",
          client_name: "",
          type_of_contract: "",
          worked_hours: "",
        });
        // Update Button
        formBtn.removeAttribute("disabled");
        formBtn.querySelector(".p-button-label").innerText = formBtn.getAttribute("aria-label");
      }
    } catch (error) {
      swal({
        title: "Error",
        text: error.message,
        icon: "warning",
      });
    }
  };

  const clientList = props.items
    ? removeDuplicates(splitColumn(props.items, "client_name")).map((client) => ({
        clientName: client,
      }))
    : [];

  return (
    <div className="col-12 flex flex-column align-items-center text-left">
      <form id="addrecordForm" className="row form-theme" onSubmit={handleSubmit}>
        <div className="col-12">
          <small className="d-block text-left full-width"><b>Add Record By Client</b></small>
        </div>
        <div className="col-6 col-sm-4 col-md-4 col-lg-12 field-row">
          <Calendar
            className="field-small p-inputtext-sm"
            placeholder="Add Date"
            name="date"
            value={newRecordData.date}
            onChange={handleInput}
            dateFormat="dd MM yy"
            required
          />
        </div>
        <div className="col-6 col-sm-4 col-md-4 col-lg-12 field-row d-none">
          <InputText
            className="p-inputtext-sm"
            placeholder="Freelancer Name"
            autoComplete="off"
            name="freelancer_name"
            value={newRecordData.freelancer_name}
            onChange={handleInput}
            disabled
            required
          />
        </div>
        <div className="col-6 col-sm-4 col-md-4 col-lg-12 field-row">
          <Dropdown
            className="p-inputtext-sm"
            placeholder="Client Name"
            options={clientList}
            optionLabel="clientName"
            name="client_name"
            value={newRecordData.client_name}
            onChange={handleInput}
            editable
          />
        </div>
        {projectList.length > 0 && (
          <div className="col-6 col-sm-4 col-md-4 col-lg-12 field-row">
            <Dropdown
              className="p-inputtext-sm"
              placeholder="Select Project"
              options={projectList}
              optionLabel="name"
              name="project_id"
              value={newRecordData.project_id}
              onChange={handleInput}
              required
            />
          </div>
        )}
        <div className="col-6 col-sm-6 col-md-6 col-lg-12 field-row">
          <Dropdown
            className="p-inputtext-sm"
            placeholder="Type Of Contract"
            options={typeOfContracts}
            optionLabel="name"
            name="type_of_contract"
            value={newRecordData.type_of_contract}
            onChange={handleInput}
            required
          />
        </div>
        <div className="col-6 col-sm-6 col-md-6 col-lg-12 field-row">
          <InputText
            className="p-inputtext-sm"
            placeholder="HH:MM"
            name="worked_hours"
            value={newRecordData.worked_hours}
            onChange={handleInput}
            required
          />
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 field-row">
          <InputTextarea
            className="p-inputtext-sm"
            placeholder="Task Memo"
            name="task_memo"
            value={newRecordData.task_memo}
            onChange={handleInput}
            required
          />
        </div>
        <div className="col-12 col-md-12 col-lg-12 field-row">
          <Button type="submit" label="Add Record" in-progress="Adding.." size="Small" className="small" />
        </div>
      </form>
    </div>
  );
};

export default Addrecord;
