import { useState, useEffect } from "react";
import React from 'react';
import axios from 'axios';
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";     
//core
import 'primeicons/primeicons.css';
import "primereact/resources/primereact.min.css";        
import { DataTable } from 'primereact/datatable/datatable.esm.js';
import { FilterMatchMode, FilterOperator } from 'primereact/api/api.esm.js';
import { Column } from 'primereact/column/column.esm.js';
import { InputText } from 'primereact/inputtext/inputtext.esm.js';
import { Button } from 'primereact/button/button.esm.js';
import { Dropdown } from 'primereact/dropdown/dropdown.esm.js';
import { Checkbox } from 'primereact/checkbox/checkbox.esm.js';

import Cookies from 'js-cookie';
import {isLoggedIn} from '../helpers.js';
import {userRoles} from '../userprofile.js';

let userData = isLoggedIn(Cookies);

let domainOrigin = "";
let origin = window.origin;
if(origin.includes("reporting.milgenx.com")){
    domainOrigin = "https://reportingapi.milgenx.com";
}else{
    domainOrigin = "http://localhost:8080";   
}

const Managestaff = () => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);

    let [listCount, setListCount] = useState(1);
    useEffect(() => {
        const getCount = async () => {
        // Till the data is fetch using API 
        // the Loading page will show.
        // setLoading(true);
        // Await make wait until that 
        // promise settles and return its result
        let requestUrI = domainOrigin+"/users-list";
        setIsLoaded(false);
        const response = await axios.get(requestUrI);

            // After fetching data stored it in posts state.
            if(response.status === 200){
            let result = response.data;
            setIsLoaded(true);
            setItems(result);
            setDataCount(result.length);
            // let leadCount = result[0]['Total'];
            // setItems(result);
            // console.log("Data Loaded" + leadCount)
            console.log(result)

            }
        }
        getCount()
        // forceUpdate()
    }, [listCount])

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'country.name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    });
    const [loading, setLoading] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [DataCount, setDataCount] = useState(0);
    const [rowClick, setRowClick] = useState(true);

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        setLoading(true);
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
        setLoading(false);
        console.log(filters)
    };
    
    const calcFilterdData = (name) => {
        let total = 0;
        return total;
    };

    const shoCheckUncheck = (rowData) => {
        let value = rowData.access_allowed;
        let show = "No";
        if(value === 0){ show = "No"; }else{ show = "Yes"; }
        return (
            <span>{show}</span>
        );
    };

    const onRowEditComplete = (e) => {
        let _items = [...items];
        let { newData, index } = e;

        _items[index] = newData;
        let editedRow = _items[index];
        // setProducts(_rowData);

        console.log({editedRow})

        const editedRowData = {...editedRow, id : editedRow.id};
        
        axios({
            method: 'post',
            url: domainOrigin+'/users-list/update',
            data: {editedRowData}
        })
        .then((response) => {
            if(response.status === 200){
                // alert("Service Center Updated");
                setListCount(listCount + 1);
            }
        })
        .catch((error) => {
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
            } else if (error.request) {
                alert(error.message);
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the 
                // browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
            console.log(error.config);
        });

    };

    const textEditor = (options) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };

    const selectEditor = (selectOptions) => {
        console.log({selectOptions})
        let checked = selectOptions.value;
        let fieldValue = selectOptions.value;
        let value = 1;
        if(fieldValue === true || fieldValue === 1){
            checked = true;
            value = 1;
        }else if(fieldValue === false|| fieldValue === 0){
            checked = false;
            value = 0;
        }
        return <Checkbox onChange={e => selectOptions.editorCallback(e.target.checked)} value={value} checked={checked}></Checkbox>;
    };

    const roleEditor = (options) => {
        const roles = userRoles();
        console.log({roles})
        return <Dropdown value={options.value} onChange={(e) => options.editorCallback(e.target.value.name)} options={roles} optionLabel="name"  placeholder={(options.value)? options.value : "Select A Role"} className="w-full md:w-14rem" />;
    };

    const [selectedRows, setSelectedRows] = useState([]);
    console.log(selectedRows)
    function deleteSelectedRows(choice) {
        let selectedRowsData = selectedRows.Sr;
        let text = "Do you wanna remove "+ selectedRowsData.employee_name + " from staff list?";
        if (window.confirm(text) === true) {
            axios({
                method: 'delete',
                url: domainOrigin+'/users-list/remove',
                data: {selectedRowsData}
            })
            .then((response) => {
                console.log(response)
                if(response.status === 200){
                    alert(response.data);
                    setListCount(listCount + 1);
                    setSelectedRows([]);
                }
            })
            .catch((error) => {
                // Error
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                } else if (error.request) {
                    alert(error.message);
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the 
                    // browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
        } else {
            // text = "You canceled!";
        }
        
    }

    const renderHeader = () => {
        const changeState = () => {  
            listCount = listCount + 1;
            setListCount(listCount);
            console.log(".. "+ listCount)
        }
        return (
            <div className="d-flex justify-content-between full-width">
                <span className="p-input-icon-left">
                    <i className="pi pi-search small" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </span>

                <div className="flex align-items-center justify-content-end gap-2">
                    {/* <Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV(true)} data-pr-tooltip="CSV" /> */}
                    <Button type="button" selected-row={(selectedRows!= null) ? selectedRows.length : 0 } className="mx-2 danger" label="Delete" severity="danger" onClick={deleteSelectedRows}/>
                    <Button type="button" className="mx-2" label="Refresh" onClick={changeState} />
                    {/* <Button type="button" label="Download in Excel" onClick={exportExcel} data-pr-tooltip="XLS" /> */}
                    {/* <Button type="button" label="Download in PDF" onClick={exportPdf} data-pr-tooltip="PDF" /> */}
                    <span className="header-count d-inline-block">Count: {DataCount}</span>
                </div>

                {/* <Button icon="pi pi-download" /> */}
            </div>
        );
    };

    const isSelectable = (items) => items.role != 'founder' && items.role != 'co-founder';
    const isRowSelectable = (event) => (event.data ? isSelectable(event.data) : true);
    const rowClassName = (items) => (isSelectable(items) ? '' : 'p-disabled');

    const header = renderHeader();
    // console.log({selectedRows})
    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {
        // setLoading(false);
        return (
        <div className="full-width">
            <div className="data-table-preview widget-layout full-width" >
                <DataTable value={items} selectionMode={rowClick ? null : 'checkbox'} selection={selectedRows} onSelectionChange={(e) => setSelectedRows(e.value)} paginator scrollable filters={filters} isDataSelectable={isRowSelectable} rowClassName={rowClassName} onRowEditComplete={onRowEditComplete} editMode="row" size="small" header={header} loading={loading} globalFilterFields={['Partner', 'Region', 'ASPName', 'Address1', 'Pincode', 'MobileNo', 'TelNo', 'CityName', 'StateName', 'ContactPerson' ]} scrollHeight="80vh" rows={30} rowsPerPageOptions={[30, 100, 250]} tableStyle={{ minWidth: '100%' }}>
                    {/* <Column className="ASPCode" field="ASPCode" header="ASP Code"></Column>
                    <Column className="Region" field="Region" header="Region"></Column> */}
                    <Column selectionMode="single" headerStyle={{ width: '3rem' }}></Column>
                    <Column className="employee_id" field="employee_id" header="EMP ID" editor={(options) => textEditor(options)}></Column>
                    <Column className="employee_name" field="employee_name" header="EMP Name"></Column>
                    <Column className="employee_email" field="employee_email" header="EMP Email" ></Column>
                    <Column className="employee_phone" field="employee_phone" header="EMP Phone" editor={(options) => textEditor(options)}></Column>
                    <Column className="department" field="department" header="Department"></Column>
                    <Column editor={(options) => selectEditor(options)} className="access_allowed" field="access_allowed" header="Access" body={shoCheckUncheck}></Column>
                    <Column editor={(options) => roleEditor(options)} className="role" field="role" header="Role"></Column>
                    <Column rowEditor header="Action" bodyStyle={{ textAlign: 'center' }}></Column>
                </DataTable>
            </div>
        </div>
        );
    }
}

export default Managestaff;