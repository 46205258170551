import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect, useContext }  from 'react';
import Recordsbysourcelist from './Recordsbysourcelist.js'
import Addrecord from './Addrecord.js'
import { TabView, TabPanel } from 'primereact/tabview/tabview.esm.js';
import axios from 'axios';


import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import Notallowed from '../myaccount/Notallowed.js';
import {isLoggedIn, getPrivacyStandard} from '../helpers.js';
let userData = isLoggedIn(Cookies);
let privacyControl = getPrivacyStandard(Cookies);
let domainOrigin = "";
let origin = window.origin;
if(origin.includes("reporting.milgenx.com")){
    domainOrigin = "https://reportingapi.milgenx.com";
}else{
    domainOrigin = "http://localhost:8080";   
}

export const context = React.createContext();

const Recordsbysource = () => {
    const navigate = useNavigate();
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [projects, setProjects] = useState([]);
    
    const [entryAdded, setEntryAdded] = useState(1);
  
    useEffect(() => {
        const getCount = async () => {
            let requestUrI = domainOrigin+"/daily-reporting";
            setIsLoaded(false);
            const response = await axios.get(requestUrI);
            // After fetching data stored it in posts state.
            if(response.status === 200){
                let result = response.data;
                setIsLoaded(true);
                setItems(result.reverse());
            }

            let requestProjectsUrI = domainOrigin+"/projects";
            setIsLoaded(false);
            const getProjects = await axios.get(requestProjectsUrI);
            // After fetching data stored it in posts state.
            if(getProjects.status === 200){
                let result = getProjects.data;
                setIsLoaded(true);
                setProjects(result.reverse());
            }
        }
        getCount()
        // forceUpdate();
    }, [entryAdded])

    

    if(userData.loggedIn === 'true' && Number(userData.accessAllowed) === 1){
    return (
        <context.Provider value={[entryAdded, setEntryAdded]}>
        <div className='leads-board full-width'>
            
            <TabView className="p-0">
                <TabPanel header="Add/Update Records" className="p-0">    
                    <div className='container-fluid full-width'>
                        <div className='row'>
                            <div className='col-12 col-md-12 col-lg-3 pt-2 bg-grey'>
                                <Addrecord items={items} projects={projects} />
                            </div>
                            <div className='col-12 col-md-12 col-lg-9 px-0'>
                                <Recordsbysourcelist items={items} viewMode="only-user" userDetails={userData} />
                            </div>
                        </div>
                    </div>
                </TabPanel>

                {privacyControl === false &&
                    <TabPanel header="All Records" className="p-0">  
                    <Recordsbysourcelist viewMode="all" userDetails={userData} />
                    </TabPanel>
                }
                
                {/* <TabPanel header="Performance" className="p-0">  
                    <Reporting />
                </TabPanel>
                <TabPanel header="Analysis" className="p-0">  
                    <Recordsanalysis />
                </TabPanel> */}
            </TabView>
        </div>
        </context.Provider>
    )
    }else{
        navigate("/my-account");
        return(
            <Notallowed/>
        )
    }
}
export default Recordsbysource;