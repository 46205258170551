import { isLoggedIn } from './helpers.js';
import Cookies from 'js-cookie';

export function userRoles(){
    console.log(">>");

    let founderRoles = [
        { name: 'Founder', code: 'founder' },
        { name: 'Co Founder', code: 'co-founder' },
    ];
    let devStaffRoles = [
        { name: 'Jr. Developer', code: 'jr-developer' },
        { name: 'Sr. Developer', code: 'sr-developer' }
    ];
    let designStaffRoles = [
        { name: 'Jr. Designer', code: 'jr-designer' },
        { name: 'Sr. Designer', code: 'sr-designer' }
    ];
    let webDesignStaffRoles = [
        { name: 'Jr. Web Designer', code: 'jr-web-designer' },
        { name: 'Sr. Web Designer', code: 'sr-web-designer' }
    ];
    let supportStaffRoles = [
        { name: 'Jr. Data Entry Personnel', code: 'jr-data-entry' },
        { name: 'Sr. Data Entry Expert', code: 'sr-data-entry' }
    ];
    let seoStaffRoles = [
        { name: 'Jr. SEO Executive', code: 'jr-seo-executive' },
        { name: 'Sr. SEO Expert', code: 'sr-seo-expert' }
    ];
    let roles = [
        { name: 'Select A Role', code: '' }
    ];
    let crUserRole = isLoggedIn(Cookies).userRole;


    if(crUserRole === "founder" || crUserRole === "co-founder"){
        roles = roles.concat.apply(founderRoles, [devStaffRoles, supportStaffRoles, seoStaffRoles, designStaffRoles, webDesignStaffRoles]);
    }else if(crUserRole === "sr-designer"){
        roles = roles.concat(devStaffRoles);
    }else if(crUserRole === "sr-web-designer"){
        roles = roles.concat(devStaffRoles);
    }else if(crUserRole === "sr-developer"){
        roles = roles.concat(devStaffRoles);
    }else if(crUserRole === "sr-data-entry"){
        roles = roles.concat(supportStaffRoles);
    }else if(crUserRole === "sr-seo-expert"){
        roles = roles.concat(seoStaffRoles);
    }else{
        roles = roles.concat(founderRoles);
    }
    return roles;
}

export function rolePermissions(){
    let permissions = [
        {role: "marketing-manager", capabilities : [{module: "lead-sources", add: true, update: true, delete: true}, {module: "sc-list", add: true, update: true, delete: true}]},
        {role: "marketing-exec", capabilities : [{module: "lead-sources", add: true, update: true, delete: true}]},
        {role: "support-manager", capabilities : [{module: "sc-list", add: true, update: true, delete: true}]},
        {role: "support-exec", capabilities : [{module: "sc-list", add: true, update: true, delete: false}]},
    ];
    return permissions;
}