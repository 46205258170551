import { Link, useLocation } from "react-router-dom";
import React, {useState} from "react";
import { Calendar } from 'primereact/calendar/calendar.esm.js';
import { DataTable } from 'primereact/datatable/datatable.esm.js';
import { Column } from 'primereact/column/column.esm.js';
import { InputText } from 'primereact/inputtext/inputtext.esm.js';
import { FilterMatchMode } from 'primereact/api/api.esm.js';
import { Button } from 'primereact/button/button.esm.js';
import { splitRows, parseDate, getDaysArray } from '../helpers.js';

export default function TrackingInDetail(props){
    const location = useLocation();
    const propsData = location.state;   
    let clientRecords = propsData.clientRecords;

    let [listCount, setListCount] = useState(1);
    const [ignored, forceUpdate] = useState(listCount);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'country.name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    });

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        setLoading(true);
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
        setLoading(false);
    };

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(clientRecords);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, "daily-records");
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    const [dateRange, setdateRange] = useState(null);
    let fromDate = '';
    let endDate = '';
    if(dateRange){
        let unFilteredRecords = clientRecords;
        if(dateRange[0]){
            fromDate = parseDate(dateRange[0]);
        }
        if(dateRange[1]){
            endDate = parseDate(dateRange[1]);
        }

        var selectedDays = getDaysArray(new Date(fromDate),new Date(endDate));
        selectedDays.map((v)=>v.toISOString().slice(0,10)).join("");

        let filteredRecords = []
        selectedDays.forEach(function(selectedDay){
            let recordDay = parseDate(selectedDay);
            filteredRecords.push(splitRows(unFilteredRecords, 'date', recordDay));
        })

        if(filteredRecords.length > 0){
            filteredRecords = filteredRecords.flat();
            clientRecords = filteredRecords;
        } 
    }

    const renderHeader = () => {
        let itemsCount = clientRecords.length;
        const changeState = () => {  
            listCount = listCount + 1;
            setListCount(listCount);
            // console.log(".. "+ listCount);
        }
        return (
            <div className="d-flex flex-wrap justify-content-between">
                <div className="heading full-width">
                    <small onClick={changeState}>Showing {propsData.clientName} Records (Count: {itemsCount})</small>
                </div>
                <div className='d-flex flex-wrap justify-content-between full-width'>
                    <div className="d-flex py-1 align-items-center justify-content-end gap-2"> 
                        <span className="p-input-icon-left">
                            <i className="pi pi-search small" />
                            <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                        </span>
                        <Calendar value={dateRange} onChange={(e) => setdateRange(e.value)} selectionMode="range" readOnlyInput showIcon />
                    </div>

                    <div className="d-flex py-1 align-items-center justify-content-end gap-2">
                        {/* <Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV(true)} data-pr-tooltip="CSV" /> */}
                        <Button type="button" label="Refresh" onClick={changeState} />
                        <Button type="button" label="Download in Excel" onClick={exportExcel} data-pr-tooltip="XLS" />
                        {/* <Button type="button" label="Download in PDF" onClick={exportPdf} data-pr-tooltip="PDF" /> */}
                    </div>
                    {/* <Button icon="pi pi-download" /> */}
                </div>
            </div>
        );
    };
    const header = renderHeader();
    
    const labelBilling = (rowData) => {
        let typeofBilling = rowData.type_of_contract;
        console.log({typeofBilling})
        return (
            <span className={`pill-label ${typeofBilling}`}>{typeofBilling.replace("-", " ")}</span>
        );
    };

    return (
        <div className="full-width">
            <div className="data-table-preview full-width">
                <DataTable className="lead-table w-100" value={clientRecords}  paginator scrollable size="small" header={header} filters={filters}
                loading={loading} 
                scrollHeight="70vh" 
                rows={30} 
                editMode="row" 
                rowsPerPageOptions={[30, 100, 250]} 
                tableStyle={{ minWidth: '100%' }} 
                globalFilterFields={['date', 'freelancer_name', 'task_memo', 'type_of_contract']}>
                    <Column field="date" header="Date"></Column>
                    <Column field="freelancer_name" header="Freelancer Name"></Column>
                    <Column field="task_memo" header="Task Memo"></Column>
                    <Column field="worked_hours" header="Worked Hours"></Column>
                    <Column field="type_of_contract" header="Type of Work" body={labelBilling}></Column>
                </DataTable>
            </div>
        </div>
    )
}