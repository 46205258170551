import React, { useState, Component, useRef }  from 'react';
import { InputText } from "primereact/inputtext/inputtext.esm.js";
import { Button } from 'primereact/button/button.esm.js';
import { Dropdown } from 'primereact/dropdown/dropdown.esm.js';
import { InputTextarea } from "primereact/inputtextarea/inputtextarea.esm.js";
import axios, {isCancel, AxiosError} from 'axios';
import { Calendar } from 'primereact/calendar/calendar.esm.js';
import Cookies from 'js-cookie';
import { domainOriginSource, statusOfProjects, typeOfProjects,isLoggedIn, splitColumn, removeDuplicates, kindOfContracts, extractDate, parseDate } from '../helpers.js';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';

// import { classNames } from 'primereact/utils';
let domainOrigin = domainOriginSource();

const Addrecord = (props) => {
    const [userData, setUserData] = useState(isLoggedIn(Cookies));
    // const [resData, setResData ] = useState([]); 
    let [listCount, setListCount] = useState(1);
    const [newRecordData, setnewRecordData] = useState({
        date : new Date(),
        freelancer_name : userData.userName,
        task_memo : "",
        client_name : "",
        type_of_contract : "",
        worked_hours : "",
    });

    let porjectTypes = typeOfProjects();
    let prodectStatus = statusOfProjects();

    
    const handleInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        
        setnewRecordData({...newRecordData, [name] : value });
    }

    const navigate = useNavigate();
    const refreshPage = () => {
        navigate(0); // Navigates to the current route, effectively refreshing the page
    }; 

    const handleSubmit = (e) => {
        e.preventDefault();
        let date = parseDate(newRecordData.date);
        let projectDeadline = parseDate(newRecordData.project_deadline);
        
        let formWraper = e.target;
        let submitBtn = formWraper.querySelector("button[type=submit]");
        submitBtn.querySelector("span.p-button-label").innerText = submitBtn.getAttribute("in-progress");

        let projectType = newRecordData.project_type.name;
        let projectStatus = newRecordData.project_status.name;

        let clientName = '';
        if(typeof newRecordData.client_name  !== 'object'){
            clientName = newRecordData.client_name;
        }else if(typeof newRecordData.client_name  === 'object'){
            clientName = newRecordData.client_name.clientName;
            console.log({clientName})
        }

        const newRecord = {...newRecordData, id : new Date().getTime().toString(), ["date"]: date, ["project_deadline"]: projectDeadline, ["project_type"] : projectType, ["project_status"] : projectStatus, ["client_name"] : clientName };
        axios({
            method: 'post',
            url: domainOrigin+'/project/submit',
            data: {newRecord}
        })
        .then((response) => {
            if(response.status === 200){
                // alert("Record Added Successfully");
                submitBtn.querySelector("span.p-button-label").innerText = submitBtn.getAttribute("aria-label");
                swal({
                    title: "Added Successfully",
                    icon: "success",
                    dangerMode: false,
                })
                .then((value) => {
                    refreshPage()
                });
                // listCount = listCount + 1;
                // setListCount(listCount);
            }
        })
        .catch((error) => {
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
            } else if (error.request) {
                // alert(error.message);
                swal({
                    title: "Errors Found",
                    text: error.message,
                    icon: "warning",
                    dangerMode: false,
                })
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the 
                // browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
            console.log(error.config);
        });
    }
    
    let allClients = [];
    let clientList = [];
    // console.log(props)
    if(props.items){
        allClients = removeDuplicates(splitColumn(props.items, "client_name"));
        // console.log(allClients)
        allClients.forEach(function(allClient){
            clientList.push({clientName:allClient});
        })
        console.log({clientList})
    }

    const drpref = useRef();
    return (
        <div className="col-12 flex flex-column align-items-center text-left">
            <form id="addrecordForm" className='row form-theme' onSubmit={handleSubmit}>
                <div className="col-12">
                    <small className='d-block text-left full-width'>Add Project</small>
                </div>
                <div className="col-6 col-sm-4 col-md-4 col-lg-12 field-row">
                    <Calendar className="field-small p-inputtext-sm" placeholder="Add Date" name="date" value={newRecordData.date} onChange={handleInput} dateFormat="d MM yy" required />
                </div>
                <div className="col-6 col-sm-4 col-md-4 col-lg-12 field-row">
                    <InputText className="p-inputtext-sm" placeholder="Project Name" autoComplete="off" name="project_name" value={newRecordData.project_name} onChange={handleInput} required />
                </div>
                <div className="col-6 col-sm-4 col-md-4 col-lg-12 field-row">
                    {/* <InputText className="p-inputtext-sm" placeholder="Client Name" autoComplete="off" name="client_name" value={newRecordData.client_name} onChange={handleInput} required/> */}
                    <Dropdown className="p-inputtext-sm" placeholder="Client Name" options={clientList} optionLabel="clientName" autoComplete="off" name="client_name" value={newRecordData.client_name} onChange={handleInput} ref={drpref} editable/>
                </div>
                <div className="col-6 col-sm-4 col-md-4 col-lg-12 field-row">
                    <Dropdown className="p-inputtext-sm" placeholder="Project Type" options={porjectTypes} optionLabel="name" autoComplete="off" name="project_type" value={newRecordData.project_type} onChange={handleInput} required/>
                </div>
                <div className="col-6 col-sm-4 col-md-4 col-lg-12 field-row">
                    <Dropdown className="p-inputtext-sm" placeholder="Project Status" options={prodectStatus} optionLabel="name" autoComplete="off" name="project_status" value={newRecordData.project_status} onChange={handleInput} required/>
                </div>
                <div className="col-6 col-sm-4 col-md-4 col-lg-12 field-row">
                    <InputText className="p-inputtext-sm" placeholder="https://project.url" autoComplete="off" name="project_url" value={newRecordData.project_url} onChange={handleInput} required />
                </div>
                {newRecordData.project_type && 
                    newRecordData.project_type.name === "Fixed Cost Project" && 
                    <>
                    <div className="col-6 col-sm-6 col-md-6 col-lg-12 field-row">
                        <InputText className="p-inputtext-sm" placeholder="Budget Hours eg.HH:MM" autoComplete="off" name="budget_hours" value={newRecordData.budget_hours} onChange={handleInput} required/>
                    </div>
                    <div className="col-6 col-sm-6 col-md-6 col-lg-12 field-row">
                        <Calendar className="field-small p-inputtext-sm" placeholder="Add Deadline" name="project_deadline" value={newRecordData.project_deadline} onChange={handleInput} dateFormat="d MM yy" required />
                    </div>
                    </>
                }
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 field-row">
                    <InputTextarea className="p-inputtext-sm" placeholder="Description" autoComplete="off" name="project_description" value={newRecordData.project_description} onChange={handleInput} required/>
                </div>
                <div className="col-12 col-md-12 col-lg-12 field-row">
                    <Button type='submit' value="Submit" label="Add Project" in-progress="Adding.." size="Small" className='small' />
                </div>
            </form>
        </div>
    )
}
export default Addrecord;