import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Routes, Route, Link, useNavigate } from 'react-router-dom';
import { isLoggedIn } from '../helpers.js';
import Cookies from 'js-cookie';
console.log("Myaccountheader");

const Myaccountheader = () =>{
    const navigate = useNavigate();
    const [userData, setUserData] = useState(isLoggedIn(Cookies));
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    function handleLogout() {
        Cookies.remove('loggedIn');
        Cookies.remove('userName');
        Cookies.remove('userEmail');
        Cookies.remove('access_allowed');
        Cookies.remove('userEmployeeID');
        Cookies.remove('user_role');
        Cookies.remove('userDepartment');

        setIsAuthenticated(false);
        setUserData(null); // Set userData to null when logging out
        navigate("/my-account");
        window.location.reload();
    };

    let LogoutBtn = "";
    let isAuthorized = userData.loggedIn;
    let userName = userData.userName;
    console.log(isAuthorized)

    return(
        <div className='profile-header p-2 bg-white mb-2'>
            <img width="30" height="30" src="/logo-square.png" alt="platform-logo" className="platform-logo" />
            <ul className="d-flex justify-content-end text-small w-100 account-header navbar-nav">
                {userData.loggedIn !== undefined && 
                    <Link to={'/daily-records'} className="nav-item nav-link pi pi-plus-circle"><b>Records</b></Link>
                }
                {userData.loggedIn !== undefined && 
                    <Link to={'/notes'} className="nav-item nav-link pi pi-file-edit"><b>Notes</b></Link>
                }
                <Link to={(userData.loggedIn === undefined) ? '/my-account' : '/dashboard'} className="nav-item nav-link account-link"><b>{(userData.loggedIn === undefined) ? 'Login/Signup' : `Hi ${userName.split(" ")[0]}`}</b></Link>
                {isAuthorized !== undefined && <Link onClick={handleLogout} className="nav-item nav-link pi pi-sign-out"></Link>}
            </ul>
        </div>
    )
}

export default Myaccountheader;