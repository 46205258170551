import React, {useState, useEffect, useContext, useRef} from 'react';
import axios from 'axios';
import { DataTable } from 'primereact/datatable/datatable.esm.js';
import { Column } from 'primereact/column/column.esm.js';
import { InputText } from 'primereact/inputtext/inputtext.esm.js';
import { InputTextarea } from "primereact/inputtextarea/inputtextarea.esm.js";
import { Calendar } from 'primereact/calendar/calendar.esm.js';
import { Dropdown } from 'primereact/dropdown/dropdown.esm.js';
import { Toast } from 'primereact/toast/toast.esm.js';
import { Dialog } from 'primereact/dialog/dialog.esm.js';
import { classNames } from 'primereact/utils/utils.esm.js';
import { kindOfContracts, splitColumn, splitRows, parseDate, getDaysArray } from '../helpers.js';
import swal from 'sweetalert';
import { context } from './Clients.js';


// import { ProductService } from './service/ProductService';
import { FilterMatchMode } from 'primereact/api/api.esm.js';
import { Button } from 'primereact/button/button.esm.js';
// import { ProductService } from './service/ProductService';
let domainOrigin = "";
let origin = window.origin;
if(origin.includes("reporting.milgenx.com")){
    domainOrigin = "https://reportingapi.milgenx.com";
}else{
    domainOrigin = "http://localhost:8080";   
}

export default function Clientlist(props) {
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [loading, setLoading] = useState(false);
    let rowClick = true;
    let error = null;

    // console.log({TableName, productHandle});   
    let [entryAdded, setEntryAdded] = useContext(context);
    let [listCount, setListCount] = useState(1);

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        setLoading(true);
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
        setLoading(false);
    };
    const [clientList, setClientList] = useState([]);
    // setClientList(props.items)
    // let clientList = props.items;

    useEffect(() => {
        const getCount = async () => {
            let requestUrI = domainOrigin+"/clients";
            setIsLoaded(false);
            const response = await axios.get(requestUrI);
            // After fetching data stored it in posts state.
            if(response.status === 200){
                let result = response.data;
                setIsLoaded(true);
                setClientList(result.reverse());
            }

            // let requestProjectsUrI = domainOrigin+"/projects";
            // setIsLoaded(false);
            // const getProjects = await axios.get(requestProjectsUrI);
            // // After fetching data stored it in posts state.
            // if(getProjects.status === 200){
            //     let result = getProjects.data;
            //     setIsLoaded(true);
            //     setClientList(result.reverse());
            // }
        }
        getCount()
        // forceUpdate();
    }, [entryAdded])

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(items);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, "client-list");
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    // const [selectedRows, setSelectedRows] = useState([]);
    // console.log(selectedRows);
    // function deleteSelectedRows(choice) {
    //     let selectedRowsData = splitColumn(selectedRows, "sr");
    //     let text = "Do you wanna remove "+ selectedRowsData.length + " record from list?";
    //     swal({
    //         title: "Are you sure?",
    //         text: text,
    //         icon: "warning",
    //         dangerMode: true,
    //       })
    //       .then(willDelete => {
    //         if (willDelete) {
    //             axios({
    //                 method: 'delete',
    //                 url: domainOrigin+'/client/remove',
    //                 data: {selectedRowsData}
    //             })
    //             .then((response) => {
    //                 // console.log(response);
    //                 if(response.status === 200){
    //                     // alert(response.data);
    //                     swal("Deleted!", "Your seleted records has deleted!", "success");
    //                     setSelectedRows([]);
    //                     setEntryAdded((prevState) => prevState + 1);
    //                 }
    //             })
    //             .catch((error) => {
    //                 // Error
    //                 if (error.response) {
    //                     // The request was made and the server responded with a status code
    //                     // that falls out of the range of 2xx
    //                     // console.log(error.response.data);
    //                     // console.log(error.response.status);
    //                     // console.log(error.response.headers);
    //                 } else if (error.request) {
    //                     // alert(error.message);
    //                     swal({
    //                         title: "Errors Found",
    //                         text: error.message,
    //                         icon: "warning",
    //                         dangerMode: true,
    //                     })
    //                     // The request was made but no response was received
    //                     // `error.request` is an instance of XMLHttpRequest in the 
    //                     // browser and an instance of
    //                     // http.ClientRequest in node.js
    //                     // console.log(error.request);
    //                 } else {
    //                     // Something happened in setting up the request that triggered an Error
    //                     // console.log('Error', error.message);
    //                     swal({
    //                         title: "Errors Found",
    //                         text: error.message,
    //                         icon: "warning",
    //                         dangerMode: true,
    //                     })
    //                 }
    //                 // console.log(error.config);
    //             });
    //         }
    //     });
    // }

    const renderHeader = () => {
        let itemsCount = clientList.length;
        const changeState = () => {  
            listCount = listCount + 1;
            setListCount(listCount);
            // console.log(".. "+ listCount);
        }
        
        return (
            <div className="d-flex flex-wrap justify-content-between">
                <div className='d-flex flex-wrap justify-content-between full-width'>
                    <div className="py-1 d-flex align-items-center justify-content-end gap-2">
                        <small onClick={changeState}>Showing All Clients (Count: {itemsCount})</small>
                    </div>
                    <div className="py-1 d-flex align-items-center justify-content-end gap-2">
                        <span className="p-input-icon-left">
                            <i className="pi pi-search small" />
                            <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search Client" />
                        </span>
                        {/* <Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV(true)} data-pr-tooltip="CSV" /> */}
                        {/*<Button type="button" selected-row={selectedRows.length} className="danger" label="Delete" severity="danger" onClick={deleteSelectedRows}/>*/}
                        <Button type="button" label="Refresh" onClick={changeState} />
                        <Button type="button" label="Download in Excel" onClick={exportExcel} data-pr-tooltip="XLS" />
                        {/* <Button type="button" label="Download in PDF" onClick={exportPdf} data-pr-tooltip="PDF" /> */}
                    </div>
                    {/* <Button icon="pi pi-download" /> */}
                </div>
            </div>
        );
    };
    const header = renderHeader();
   
    useEffect(() => {
        const getCount = async () => {
        // Till the data is fetch using API 
        // the Loading page will show.
        // setLoading(true);
        // Await make wait until that 
        // promise settles and return its result
            let requestUrI = domainOrigin+"/clients";
            setIsLoaded(false);
            const response = await axios.get(requestUrI);
            // After fetching data stored it in posts state.
            if(response.status === 200){
                let result = response.data;
                setIsLoaded(true);
                setItems(result.reverse());
            }
        }

        getCount()
        // forceUpdate();
    }, [entryAdded])

    const saveCliient = () => {
        setSubmitted(true);

        let _clientList = [...clientList];
        let _client = { ...client };

        if (client.id) {
            const index = client.id;

            _clientList[index] = _client;
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Client Details Updated', life: 3000 });

            const editedRowData = {...client, id : client.id};
    
            axios({
                method: 'post',
                url: domainOrigin+'/client/update',
                data: {editedRowData}
            })
            .then((response) => {
                if(response.status === 200){
                    // alert("Row Updated");
                    listCount = listCount + 1;
                    setListCount(listCount);
                    setEntryAdded((prevState) => prevState + 1);
                }
            })
            .catch((error) => {
                // Error
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                } else if (error.request) {
                    // alert(error.message);
                    swal({
                        title: "Errors Found",
                        text: error.message,
                        icon: "warning",
                        dangerMode: true,
                    })
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the 
                    // browser and an instance of
                    // http.ClientRequest in node.js
                    // console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    // console.log('Error', error.message);
                    swal({
                        title: "Errors Found",
                        text: error.message,
                        icon: "warning",
                        dangerMode: true,
                    })
                }
                console.log(error.config);
            });
        } else {
            _client.id = new Date().getTime().toString();
            _client.image = 'product-placeholder.svg';
            clientList.push(_client);
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Created', life: 3000 });
        }

        // setClientList(_clientList);
        setClientDialog(false);
        setClient(emptyProduct);
    };

    const contractEditor = (options) => {
        const typeOfContracts = kindOfContracts();
        return <Dropdown value={options.value} onChange={(e) => options.editorCallback(e.target.value.code)} options={typeOfContracts} optionLabel="name"  placeholder={(options.value)? options.value : "Billable"} className="w-full md:w-14rem" />;
    };
 
    const textEditor = (options) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };

    const textAreaEditor = (options) => {
        return <InputTextarea className="p-inputtext-sm" type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };
    const dateEditor = (options) => {
        return <Calendar className="" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} name='dateRange' selectionMode="single" dateFormat="mm/dd/yy" placeholder={options.value} showButtonBar />;
    };

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'country.name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    });


    const labelBilling = (rowData) => {
        let client_source = rowData.client_source;
        return (
            <span className={`pill-label ${client_source}`}>{client_source.replace("-", " ")}</span>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className="py-1 d-flex align-items-center justify-content-end gap-2">
                    <Button icon="pi pi-pencil" size="small" rounded outlined className="p-1 mr-2" onClick={() => editClient(rowData)} />
                    <Button icon="pi pi-trash" disabled size="small" rounded outlined severity="danger" onClick={() => deleteClient(rowData)} />
                </div>
            </React.Fragment>
        );
    };

    let emptyProduct = {
        id: null,
        name: '',
        image: null,
        description: '',
        category: null,
        price: 0,
        quantity: 0,
        rating: 0,
        inventoryStatus: 'INSTOCK'
    };

    const [client, setClient] = useState(emptyProduct);
    const [clientDialog, setClientDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [deleteClientDialog, setDeleteCllientDialog] = useState(false);
    const toast = useRef(null);

    const editClient = (rowData) => {
        setClient({ ...rowData });
        setClientDialog(true);
    };

    const hideDeleteClientDialog = () => {
        setDeleteCllientDialog(false);
    };

    const confirmDeleteClient = (client) => {
        setDeleteCllientDialog(true);
        let selectedRowsData = splitColumn(client, "sr");
        axios({
            method: 'delete',
            url: domainOrigin+'/client/remove',
            data: {selectedRowsData}
        })
        .then((response) => {
            // console.log(response);
            if(response.status === 200){
                // alert(response.data);
                swal("Deleted!", "Client has deleted!", "success");
                setEntryAdded((prevState) => prevState + 1);
            }
        })
        .catch((error) => {
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
            } else if (error.request) {
                // alert(error.message);
                swal({
                    title: "Errors Found",
                    text: error.message,
                    icon: "warning",
                    dangerMode: true,
                })
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the 
                // browser and an instance of
                // http.ClientRequest in node.js
                // console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                // console.log('Error', error.message);
                swal({
                    title: "Errors Found",
                    text: error.message,
                    icon: "warning",
                    dangerMode: true,
                })
            }
            // console.log(error.config);
        });
    };

    const deleteClient = (rowData) => {
        console.log(rowData)
        let selectedRowsData = rowData.sr;
        console.log({selectedRowsData})
        axios({
            method: 'delete',
            url: domainOrigin+'/client/remove',
            data: {selectedRowsData}
        })
        .then((response) => {
            // console.log(response);
            if(response.status === 200){
                // alert(response.data);
                swal("Deleted!", "Client has deleted!", "success");
                setEntryAdded((prevState) => prevState + 1);
            }
        })
        .catch((error) => {
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
            } else if (error.request) {
                // alert(error.message);
                swal({
                    title: "Errors Found",
                    text: error.message,
                    icon: "warning",
                    dangerMode: true,
                })
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the 
                // browser and an instance of
                // http.ClientRequest in node.js
                // console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                // console.log('Error', error.message);
                swal({
                    title: "Errors Found",
                    text: error.message,
                    icon: "warning",
                    dangerMode: true,
                })
            }
            // console.log(error.config);
        });
    };

    const hideDialog = () => {
        setSubmitted(false);
        setClientDialog(false);
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _client = { ...client };

        _client[`${name}`] = val;

        setClient(_client);
    };

    const clientDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" onClick={saveCliient} />
        </React.Fragment>
    );
    const deleteClientDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteClientDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick="" />
        </React.Fragment>
    );
    

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div>Loading Client List...</div>;
    } else {
        return (
        <div className="full-width">
                <div className="data-table-preview full-width">
                    <Toast ref={toast} />
                    <DataTable className="record-table" value={clientList} paginator scrollable filters={filters} size="small" header={header} 
                    loading={loading} 
                    scrollHeight="70vh" 
                    rows={30} 
                    editMode="row" 
                    rowsPerPageOptions={[30, 100, 250]} 
                    tableStyle={{ minWidth: '100%' }} 
                    globalFilterFields={['date', 'freelancer_name', 'client_name', 'type_of_contract']}>
                        <Column field="client_name" header="Name"></Column>
                        <Column field="client_phone" header="Phone"></Column>
                        <Column field="client_email" header="Email"></Column>
                        <Column field="client_source" editor={(options) => contractEditor(options)} header="Source" body={labelBilling}></Column>
                        <Column field="billing_method" editor={(options) => textEditor(options)} header="Billing Method"></Column>
                        <Column field="notes" editor={(options) => textAreaEditor(options)} header="Notes"></Column>
                        <Column body={actionBodyTemplate} header="Action" bodyStyle={{ textAlign: 'center' }}></Column>
                    </DataTable>

                    <Dialog visible={clientDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Client Details" modal className="p-fluid form-theme" footer={clientDialogFooter} onHide={hideDialog}>
                        {client.image && <img src={`https://primefaces.org/cdn/primereact/images/product/${client.image}`} alt={client.image} className="product-image block m-auto pb-3" />}
                        <div className="field-row col-12 col-md-12">
                            <label htmlFor="client_name" className="font-bold">
                                Name
                            </label>
                            <InputText id="client_name" value={client.client_name} onChange={(e) => onInputChange(e, 'client_name')} required autoFocus className={classNames({ 'p-invalid': submitted && !client.client_name })} />
                            {submitted && !client.client_name && <small className="p-error">Name is required.</small>}
                        </div>
                        <div className="field-row col-12 col-md-12">
                            <label htmlFor="company_name" className="font-bold">
                                Company Name
                            </label>
                            <InputText id="company_name" value={client.company_name} onChange={(e) => onInputChange(e, 'company_name')} required autoFocus className={classNames({ 'p-invalid': submitted && !client.company_name })} />
                            {submitted && !client.company_name && <small className="p-error">Name is required.</small>}
                        </div>
                        <div className="field-row col-12 col-md-12">
                            <label htmlFor="client_email" className="font-bold">
                                Email
                            </label>
                            <InputText id="client_email" value={client.client_email} onChange={(e) => onInputChange(e, 'client_email')} required autoFocus className={classNames({ 'p-invalid': submitted && !client.client_email })} />
                            {submitted && !client.client_email && <small className="p-error">Name is required.</small>}
                        </div>
                        <div className="field-row col-12 col-md-12">
                            <label htmlFor="client_phone" className="font-bold">
                                Phone
                            </label>
                            <InputText id="client_phone" value={client.client_phone} onChange={(e) => onInputChange(e, 'client_phone')} required autoFocus className={classNames({ 'p-invalid': submitted && !client.client_phone })} />
                            {submitted && !client.client_phone && <small className="p-error">Name is required.</small>}
                        </div>
                        <div className="field-row col-12 col-md-12">
                            <label htmlFor="client_country" className="font-bold">
                                Country
                            </label>
                            <InputText id="client_country" value={client.client_country} onChange={(e) => onInputChange(e, 'client_country')} required autoFocus className={classNames({ 'p-invalid': submitted && !client.client_country })} />
                            {submitted && !client.client_country && <small className="p-error">Name is required.</small>}
                        </div>
                        <div className="field-row col-12 col-md-12">
                            <label htmlFor="client_address" className="font-bold">
                                Address
                            </label>
                            <InputTextarea id="client_address" value={client.client_address} onChange={(e) => onInputChange(e, 'client_address')} required rows={3} cols={20} />
                        </div>
                        <div className="field-row col-12 col-md-12">
                            <label htmlFor="notes" className="font-bold">
                                Notes
                            </label>
                            <InputTextarea id="notes" value={client.notes} onChange={(e) => onInputChange(e, 'notes')} required rows={3} cols={20} />
                        </div>

                        {/* <div className="field">
                            <label className="mb-3 font-bold">Category</label>
                            <div className="formgrid grid">
                                <div className="field-radiobutton col-6">
                                    <RadioButton inputId="category1" name="category" value="Accessories" onChange={onCategoryChange} checked={client.category === 'Accessories'} />
                                    <label htmlFor="category1">Accessories</label>
                                </div>
                                <div className="field-radiobutton col-6">
                                    <RadioButton inputId="category2" name="category" value="Clothing" onChange={onCategoryChange} checked={client.category === 'Clothing'} />
                                    <label htmlFor="category2">Clothing</label>
                                </div>
                                <div className="field-radiobutton col-6">
                                    <RadioButton inputId="category3" name="category" value="Electronics" onChange={onCategoryChange} checked={client.category === 'Electronics'} />
                                    <label htmlFor="category3">Electronics</label>
                                </div>
                                <div className="field-radiobutton col-6">
                                    <RadioButton inputId="category4" name="category" value="Fitness" onChange={onCategoryChange} checked={client.category === 'Fitness'} />
                                    <label htmlFor="category4">Fitness</label>
                                </div>
                            </div>
                        </div> */}

                        {/* <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="price" className="font-bold">
                                    Price
                                </label>
                                <InputNumber id="price" value={client.price} onValueChange={(e) => onInputNumberChange(e, 'price')} mode="currency" currency="USD" locale="en-US" />
                            </div>
                            <div className="field col">
                                <label htmlFor="quantity" className="font-bold">
                                    Quantity
                                </label>
                                <InputNumber id="quantity" value={client.quantity} onValueChange={(e) => onInputNumberChange(e, 'quantity')} />
                            </div>
                        </div> */}
                    </Dialog>

                    <Dialog visible={deleteClientDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal footer={deleteClientDialogFooter} onHide={hideDeleteClientDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {client && (
                                <span>
                                    Are you sure you want to delete <b>{client.client_name}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>
                </div>
        </div>
        );
    }
}